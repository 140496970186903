import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';

import type { ComponentType, Nullable } from '../../types';

import {
  COMPONENTS_REF,
  PROFILE_PAGE_BOB_WIDGET_ID,
  SETTINGS_PAGE_BOB_WIDGET_ID,
} from '../../constants';
import { getComponentsRefs } from '../editor-sdk-wrappers';
import { log } from './monitor';

const getWidgetRef = async (
  editorSDK: FlowEditorSDK,
  widgetId: string,
  componentType: ComponentType,
) => {
  const componentsRefs = await getComponentsRefs(editorSDK, componentType);

  const [widgetRefComp] = componentsRefs.filter((refComp) => {
    const data = refComp?.data as Nullable<{ widgetId: string }>;
    return data?.widgetId === widgetId;
  });

  return widgetRefComp?.compRef ?? null;
};

export const getProfilePageBobWidgetRef = async (editorSDK: FlowEditorSDK) => {
  const ref = await getWidgetRef(
    editorSDK,
    PROFILE_PAGE_BOB_WIDGET_ID,
    COMPONENTS_REF,
  );

  if (!ref) {
    log('Member Page widgetRef is missing');
    throw new Error('Member page is missing when expected');
  } else {
    return ref;
  }
};

export const isProfilePageBobWidgetInstalled = async (
  editorSDK: FlowEditorSDK,
) =>
  !!(await getWidgetRef(editorSDK, PROFILE_PAGE_BOB_WIDGET_ID, COMPONENTS_REF));

export const getSettingsPageWidgetRef = async (editorSDK: FlowEditorSDK) => {
  const ref = await getWidgetRef(
    editorSDK,
    SETTINGS_PAGE_BOB_WIDGET_ID,
    COMPONENTS_REF,
  );

  if (ref) {
    return ref;
  }

  log('Settings Page widgetRef is missing');
  throw new Error('Settings page is missing when expected');
};
