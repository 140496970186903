import type { ContextParams } from '@wix/platform-editor-sdk';
import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { WidgetId } from '@wix/members-area-app-definitions';

import type { PrivateAPI } from '../../../types';
// import { monitoredTransactionFactory } from '../../editor/services/monitor';
// import { withHistoryFactory } from '../../editor/editor-sdk-wrappers';

const initPrivateAPI = (
  _editorSDK: FlowEditorSDK,
  _contextParams: ContextParams,
): PrivateAPI => {
  // const monitoredTransaction = monitoredTransactionFactory(editorSDK);
  // const withHistory = withHistoryFactory(editorSDK);

  return {
    getLayoutPreset: async () => {
      throw new Error(
        '[SplitMA] PrivateAPI.getLayoutPreset is not implemented',
      );
    },
    setLayoutPreset: async (layoutPreset) => {
      throw new Error(
        '[SplitMA] PrivateAPI.setLayoutPreset is not implemented',
      );
    },
    updateMenusItemsOrder: async (sourceIndex: number, targetIndex: number) => {
      throw new Error(
        '[SplitMA] PrivateAPI.updateMenusItemsOrder is not implemented',
      );
    },
    updateMenusItemLabel: async (widgetId: WidgetId, newLabel: string) => {
      throw new Error(
        '[SplitMA] PrivateAPI.updateMenusItemLabel is not implemented',
      );
    },
    openMemberSettingsBM: async (referralInfo) => {
      throw new Error(
        '[SplitMA] PrivateAPI.openMemberSettingsBM is not implemented',
      );
    },
  };
};

export const createPrivateAPI = (
  editorSDK: FlowEditorSDK,
  contextParams: ContextParams,
): PrivateAPI => {
  const getPrivateAPI = (() => {
    let _privateAPI: PrivateAPI;
    return async () => {
      if (_privateAPI) {
        return _privateAPI;
      }
      _privateAPI = initPrivateAPI(editorSDK, contextParams);
      return _privateAPI;
    };
  })();

  return {
    getLayoutPreset: async () => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.getLayoutPreset();
    },
    setLayoutPreset: async (layoutPreset) => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.setLayoutPreset(layoutPreset);
    },
    updateMenusItemsOrder: async (sourceIndex: number, targetIndex: number) => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.updateMenusItemsOrder(sourceIndex, targetIndex);
    },

    updateMenusItemLabel: async (widgetId: WidgetId, newLabel: string) => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.updateMenusItemLabel(widgetId, newLabel);
    },
    openMemberSettingsBM: async (referralInfo) => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.openMemberSettingsBM(referralInfo);
    },
  };
};
