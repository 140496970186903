import type { PageRef } from '@wix/platform-editor-sdk';

import type { PlatformContext } from '../../../types';

import { EDITOR_X_WIDGET_LAYOUT } from '../../../constants/editor-x';
import {
  PROFILE_WIDGET_OPTIONS,
  SETTINGS_WIDGET_OPTIONS,
} from '../../../constants';
import { addWidget } from '../../../editor/editor-sdk-wrappers';
import {
  toMonitored,
  transactionWithConflictMonitor,
} from '../../../editor/services/monitor';
import {
  createProfilePage,
  createSettingsPage,
  findSectionInPage,
} from '../../../editor/services/page';

const getSectionRef = (
  context: Pick<PlatformContext, 'editorSDK' | 'flowAPI'>,
  pageRef: PageRef,
) => {
  const { environment } = context.flowAPI;

  if (environment.isEditorX) {
    return;
  }

  return findSectionInPage(context, pageRef);
};

export const addMemberSettingsPage = async (
  context: Pick<PlatformContext, 'flowAPI' | 'editorSDK'>,
) => {
  const action = async () => {
    const pageRef = await toMonitored(
      'ma-split.install.create-profile-page',
      () => createSettingsPage(context),
    );
    const sectionRef = await toMonitored(
      'ma-split.install.get-section-ref',
      () => getSectionRef(context, pageRef),
    );
    const containerRef = sectionRef || pageRef;

    return addWidget(context.editorSDK, {
      ...SETTINGS_WIDGET_OPTIONS,
      layouts: EDITOR_X_WIDGET_LAYOUT,
      containerRef,
    });
  };

  return transactionWithConflictMonitor(
    context.editorSDK,
    'ma-split.add-settings-bob-widget',
    action,
  );
};

export const addProfilePageWidget = async (
  context: Pick<PlatformContext, 'flowAPI' | 'editorSDK'>,
) => {
  const transaction = async () => {
    const profilePageRef = await toMonitored(
      'ma-split.install.create-profile-page',
      () => createProfilePage(context),
    );
    const sectionRef = await toMonitored(
      'ma-split.install.get-section-ref',
      () => getSectionRef(context, profilePageRef),
    );
    const containerRef = sectionRef || profilePageRef;

    return addWidget(context.editorSDK, {
      ...PROFILE_WIDGET_OPTIONS,
      layouts: EDITOR_X_WIDGET_LAYOUT,
      containerRef,
    });
  };

  return transactionWithConflictMonitor(
    context.editorSDK,
    'ma-split.add-profile-page-bob-widget',
    transaction,
  );
};
