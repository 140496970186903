import type { PlatformContext } from '../../types';

import { toMonitored } from '../../editor/services/monitor';
import { isMemberPageInstalled as isProfilePageInstalled } from '../../editor/services/page';
import {
  addProfileCardWidget,
  alignProfileCardLayout,
} from '../../editor/services/profile-card';
import { addMemberSettingsPage, addProfilePageWidget } from './services/widget';
import { addMyAccountWidget } from './services/my-account';

export const installMemberSettingsPage = async ({
  editorSDK,
  flowAPI,
}: Pick<PlatformContext, 'editorSDK' | 'flowAPI'>) => {
  await toMonitored('ma-split.install.add-profile-page-bob-widget', () => {
    return addMemberSettingsPage({
      editorSDK,
      flowAPI,
    });
  });

  await toMonitored('ma-split.install.add-my-account-widget', () => {
    return addMyAccountWidget(editorSDK);
  });
};

export const installProfilePage = async ({
  editorSDK,
  flowAPI,
}: Pick<PlatformContext, 'editorSDK' | 'flowAPI'>) => {
  await toMonitored('ma-split.install.add-profile-page-bob-widget', () => {
    return addProfilePageWidget({
      editorSDK,
      flowAPI,
    });
  });

  await addProfileCardWidget(editorSDK);
  await alignProfileCardLayout(editorSDK);
};

export const installProfilePageIfMissing = async (
  context: Pick<PlatformContext, 'editorSDK' | 'flowAPI'>,
) => {
  if (await isProfilePageInstalled(context.editorSDK)) {
    return;
  }

  await installProfilePage(context);
};
