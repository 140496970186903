import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { WidgetId } from '@wix/members-area-app-definitions';

import type { EditorMenuItem, MenuData } from '../../types';
import { getMenu, updateMenu } from '../editor-sdk-wrappers';
import { MenuId } from '../../constants';
import { getReorderedItems } from '../../utils';

import {
  filterOutMenuItemsByIds,
  getMenuDataWithUpdatedItems,
  getMenuItemsWithReplacedMembersPath,
} from './utils';

export const getMembersSubMenu = (editorSDK: FlowEditorSDK) => {
  return getMenu(editorSDK, MenuId.MembersSubMenu);
};

export const getWidgetIdsSetFromSubMenu = async (editorSDK: FlowEditorSDK) => {
  const { items = [] } = await getMembersSubMenu(editorSDK);

  return new Set(
    items.map((menuItem) => menuItem?.link?.itemId as WidgetId).filter(Boolean),
  );
};

export const getWidgetIdsFromSubMenu = async (
  editorSDK: FlowEditorSDK,
): Promise<WidgetId[]> => {
  const { items = [] } = await getMembersSubMenu(editorSDK);
  return items
    .map((menuItem) => menuItem?.link?.itemId as WidgetId)
    .filter(Boolean);
};

export const updateMembersSubMenu = (
  editorSDK: FlowEditorSDK,
  menuData: MenuData,
) => {
  return updateMenu(editorSDK, MenuId.MembersSubMenu, menuData);
};

export const removeMembersSubMenuItems = async (
  editorSDK: FlowEditorSDK,
  widgetsIds: WidgetId[],
) => {
  const subMenu = await getMembersSubMenu(editorSDK);

  const filteredSubMenuItems = filterOutMenuItemsByIds(
    subMenu.items,
    widgetsIds,
  );

  const menuWithUpdatedItems = getMenuDataWithUpdatedItems(
    subMenu,
    filteredSubMenuItems,
  );

  return updateMembersSubMenu(editorSDK, menuWithUpdatedItems);
};

export const updateMembersSubMenuItemsOrder = async (
  editorSDK: FlowEditorSDK,
  sourceIndex: number,
  targetIndex: number,
) => {
  const subMenu = await getMembersSubMenu(editorSDK);

  const reorderedSubMenuItems = getReorderedItems<EditorMenuItem>(
    sourceIndex,
    targetIndex,
    subMenu.items,
  );

  const menuWithUpdatedItems = getMenuDataWithUpdatedItems(
    subMenu,
    reorderedSubMenuItems,
  );

  return updateMembersSubMenu(editorSDK, menuWithUpdatedItems);
};

export const updateMembersSubMenuItemLabel = async (
  editorSDK: FlowEditorSDK,
  widgetId: WidgetId,
  newLabel: string,
) => {
  const subMenu = await getMembersSubMenu(editorSDK);

  const updatedSubMenuItems = subMenu.items?.map((menuItem) => {
    return menuItem?.link?.itemId === widgetId
      ? { ...menuItem, label: newLabel }
      : menuItem;
  });

  const menuWithUpdatedItems = getMenuDataWithUpdatedItems(
    subMenu,
    updatedSubMenuItems,
  );

  return updateMembersSubMenu(editorSDK, menuWithUpdatedItems);
};

export const updateMembersSubMenuMembersPath = async (
  editorSDK: FlowEditorSDK,
  currentPageUriSEO: string,
  newPageUriSEO: string,
) => {
  const subMenu = await getMembersSubMenu(editorSDK);

  const updatedSubMenuItems = getMenuItemsWithReplacedMembersPath(
    subMenu.items,
    currentPageUriSEO,
    newPageUriSEO,
  );

  const menuWithUpdatedItems = getMenuDataWithUpdatedItems(
    subMenu,
    updatedSubMenuItems,
  );

  return updateMembersSubMenu(editorSDK, menuWithUpdatedItems);
};
