import type {
  EditorScriptFlowAPI,
  FlowEditorSDK,
} from '@wix/yoshi-flow-editor';

import { getSettingsPageRef } from '../../editor/services/page';
import { Experiment } from '../../constants';

export const shouldUseSplitPlatformApp = async (
  editorSDK: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI,
  options?: Partial<{ firstInstall: boolean }>,
) => {
  const isSplitEnabled = flowAPI.experiments.enabled(
    Experiment.SplitInstallation,
  );

  // TODO: While split is in early working phase it is better to do not
  // perform additional checks if FT is disabled.
  if (!isSplitEnabled) {
    return false;
  }

  if (options?.firstInstall) {
    return isSplitEnabled;
  }

  try {
    const settingsPageRef = await getSettingsPageRef(editorSDK);
    return !!settingsPageRef;
  } catch {
    return false;
  }
};
